import {useMemo, useState} from 'react';
import * as React from 'react';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import classnames from 'classnames';

import {MHRegularButton} from '../../../buttons';
import MHTextField from '../../../MHTextField';
import {MHPaginationProps} from '../types';
import {buildVisiblePages} from './utils';

import styles from './mhPaginationActions.module.scss';

type PaginationActionProps = Omit<MHPaginationProps, 'rowsPerPageOptions' | 'onChangeRowsPerPage'>;

export const MHPaginationActions = ({count, page, size, onPageChange}: PaginationActionProps) => {
    const [goToPage, setGoToPage] = useState('');

    const pagesCount = Math.max(1, Math.ceil(count / size));
    const pagesArray = [...Array(pagesCount).keys()].map((item) => item + 1);

    const visiblePages = useMemo(() => buildVisiblePages(pagesArray, page), [page, pagesArray]);

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
        if (page > 0) onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
        if (pagesCount > page + 1) onPageChange(event, page + 1);
    };

    const goToPageHandler = () => {
        const goToPageNumber = parseInt(goToPage);

        if (goToPageNumber) {
            onPageChange(null, pagesArray.includes(goToPageNumber) ? goToPageNumber - 1 : pagesCount - 1);
        }
    };

    const handleInputKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            goToPageHandler();
        }
    };

    return (
        <div className={styles.paginationWrapper}>
            <IconButton
                disabled={page === 0}
                onClick={handleBackButtonClick}
                aria-label="previous page"
                classes={{root: styles.paginationArrow}}
                size="large"
            >
                <KeyboardArrowLeft />
            </IconButton>

            {visiblePages.map((item, index) => (
                <div
                    key={`${item}-${index}`}
                    onClick={() => {
                        if (typeof item === 'number') {
                            // check if event null does not break anything
                            onPageChange(null, item - 1);
                        }
                    }}
                    data-testid={`paginationItem${item}`}
                    className={classnames(
                        styles.paginationItem,
                        {[styles.paginationItemActive]: item === page + 1},
                        {[styles.paginationItemDivider]: typeof item === 'string'}
                    )}
                >
                    {item}
                </div>
            ))}

            <IconButton
                disabled={page === pagesCount - 1}
                onClick={handleNextButtonClick}
                aria-label="next page"
                classes={{root: styles.paginationArrow}}
                size="large"
            >
                <KeyboardArrowRight />
            </IconButton>

            <div className={styles.goToPage__wrapper}>
                <div className={styles.goToPage__title}>Go to page</div>

                <MHTextField
                    rootClassName={styles.goToPage__inputWrapper}
                    inputBaseClasses={{input: styles.goToPage__input}}
                    onKeyPress={handleInputKeyPress}
                    value={goToPage}
                    onChange={({target}) => {
                        setGoToPage(target.value);
                    }}
                />
                <MHRegularButton
                    text="Go"
                    onClick={goToPageHandler}
                    btnType="secondary-positive"
                    btnSize="small"
                    margin="0 0 0 8px"
                />
            </div>
        </div>
    );
};
