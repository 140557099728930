import {ReactNode} from 'react';
import {ErrorOutlineOutlined, InfoOutlined} from '@mui/icons-material';
import {Grid} from '@mui/material';
import classnames from 'classnames';

import {MHTypography, MHTypographyColor, MHTypographyVariant} from '../MHTypography';

import styles from './mhBanner.module.scss';

type BannerType = 'error' | 'warning' | 'info' | 'success';

type BannerStyle = {
    icon: ReactNode;
    containerStyle: string;
    textVariant?: MHTypographyVariant;
    textColor?: MHTypographyColor;
};

const bannerStyleMap: Record<BannerType, BannerStyle> = {
    success: {
        containerStyle: styles.containerSuccess,
        icon: <InfoOutlined className={styles.iconSuccess} />,
    },
    info: {
        containerStyle: styles.containerInfo,
        icon: <InfoOutlined className={styles.iconInfo} />,
    },
    warning: {
        containerStyle: styles.containerWarning,
        icon: <InfoOutlined className={styles.iconWarning} />,
    },
    error: {
        containerStyle: styles.containerError,
        icon: <ErrorOutlineOutlined className={styles.iconError} />,
        textColor: 'red_100',
        textVariant: 'labelM',
    },
};

type MHBannerProps = {
    children: ReactNode;
    type: BannerType;
};

export const MHBanner = ({type, children}: MHBannerProps) => {
    const {icon, containerStyle, textColor, textVariant = 'bodyM'} = bannerStyleMap[type];

    return (
        <Grid
            container
            alignItems="flex-start"
            gap="16px"
            flexWrap="nowrap"
            padding="8px 16px"
            borderRadius="4px"
            border="1px solid"
            className={classnames(containerStyle)}
        >
            <Grid item display="flex">
                {icon}
            </Grid>
            <Grid item flexGrow={1}>
                <MHTypography variant={textVariant} color={textColor}>
                    {children}
                </MHTypography>
            </Grid>
        </Grid>
    );
};
