import {
    ClearIndicatorProps,
    components,
    DropdownIndicatorProps,
    GroupBase,
    MultiValueProps,
    MultiValueRemoveProps,
    OptionProps,
} from 'react-select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import {MHToolTip, MHTypography} from '@/components/base';

import MHLoader from '../MHLoader';
import {TypedOption} from './types';

import styles from './styles/componentOverrides.module.scss';

const DropdownIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: DropdownIndicatorProps<Option, IsMulti, Group>
) => (
    <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
    </components.DropdownIndicator>
);

const ClearIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: ClearIndicatorProps<Option, IsMulti, Group>
) => (
    <components.ClearIndicator {...props}>
        <div className={styles.removeAllText}>Clear</div>
    </components.ClearIndicator>
);

const LoadingIndicator = () => <MHLoader className={styles.loadingIcon} />;

const MultiValueRemove = <Option, IsMulti extends boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: MultiValueRemoveProps<Option, IsMulti, Group>
) => (
    <components.MultiValueRemove {...props}>
        <CloseIcon />
    </components.MultiValueRemove>
);

const Option = <Option, IsMulti extends boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: OptionProps<Option, IsMulti, Group>
) => (
    <div>
        <components.Option {...props}>
            <FormControlLabel
                classes={{root: styles.multiOptionLabel}}
                control={
                    <Checkbox classes={{root: styles.multiOptionCheckbox}} checked={props.isSelected} color="primary" />
                }
                label={props.label}
            />
        </components.Option>
    </div>
);

const MultiValue = (props: MultiValueProps<TypedOption<string, string>> & {multiValueMaxVisible?: number}) => {
    const {index, getValue, multiValueMaxVisible} = props;
    const value = getValue();
    const visibleValue = value.slice(multiValueMaxVisible).map((el) => el.label);
    const tooltipContent = visibleValue.map((el) => <div key={el}>{el}</div>);

    return !multiValueMaxVisible || index < multiValueMaxVisible ? (
        <components.MultiValue {...props}>{props.children}</components.MultiValue>
    ) : index === multiValueMaxVisible ? (
        <MHToolTip tooltipContent={tooltipContent}>
            <MHTypography
                marginLeft="4px"
                variant="labelM"
                color="teal_100"
            >{`+${tooltipContent.length}`}</MHTypography>
        </MHToolTip>
    ) : null;
};

export {ClearIndicator, DropdownIndicator, LoadingIndicator, MultiValue, MultiValueRemove, Option};
