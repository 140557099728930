import {ReactNode} from 'react';
import {PopperPlacementType, PopperProps} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';

import styles from './styles.module.scss';

// AutoPlacement is not exported, so we recreate it here
type AutoPlacement = 'auto' | 'auto-start' | 'auto-end';

const isAuto = (placement: PopperPlacementType): placement is AutoPlacement => {
    return placement.startsWith('auto');
};

type MHToolTipProps = {
    arrow?: boolean;
    children?: ReactNode;
    tooltipContent?: ReactNode;
    placement?: PopperPlacementType;
    light?: boolean;
    black?: boolean;
    disableInteractive?: boolean;
    popperClass?: string;
    tooltipClass?: string;
    popperProps?: Partial<PopperProps>;
    open?: boolean;
};

const MHToolTip = ({
    arrow = false,
    children,
    tooltipContent,
    placement = 'top-start',
    light = false,
    black = false,
    disableInteractive,
    popperClass,
    tooltipClass,
    popperProps,
    open,
}: MHToolTipProps) => {
    // there are additional "auto" placements on Popper itself that MUI doesn't yet allow
    // https://popper.js.org/docs/v2/constructors/#placement
    const PopperProps = {
        ...(popperProps ? popperProps : {}),
        ...(isAuto(placement) ? {placement} : {}),
    };

    return (
        <Tooltip
            open={open}
            classes={{
                tooltip: classnames(styles.tooltipModalBasic, tooltipClass, {
                    [styles.tooltipModalWhite]: light,
                    [styles.tooltipModalBlack]: black,
                }),
                popper: classnames(styles.popperStyles, popperClass),
                arrow: classnames({
                    [styles.tooltipArrowWhite]: light,
                    [styles.tooltipArrowBlack]: black,
                }),
            }}
            PopperProps={PopperProps}
            arrow={arrow}
            title={tooltipContent}
            placement={isAuto(placement) ? undefined : placement}
            disableInteractive={disableInteractive}
        >
            <span>{children}</span>
        </Tooltip>
    );
};

export default MHToolTip;
