import {memo, useMemo} from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import classnames from 'classnames';

import {MHCheckbox, MHTableRowElement} from '@/components/base';

import {MHTableCell} from '../MHTableCell';
import {MHTableBaseRow, MHTableColumnConfig, MHTableRowProps} from '../shared/types';
import {ExpandedRow} from './ExpandedRow';

import styles from './mhTableRow.module.scss';

const getUniqueKey = <I extends MHTableBaseRow>(column: MHTableColumnConfig<I>) => {
    if (column.key) return column.key;
    if (column.sortKey && typeof column.title === 'string') return `${column.sortKey}-${column.title}`;
    if (column.sortKey) return column.sortKey;
    if (column.title && typeof column.title === 'string') return column.title;
};

const MHTableRowNotMemoized = <I extends MHTableBaseRow>({
    row,
    selected = false,
    columns,
    disableSelect,
    expandableContent,
    externalRowStyles,
    externalCellStyles = '',
    onRowClick,
    hover = true,
    isExpanded,
    onExpand,
    syncExpandable,
    onRowSelect,
    isExpandable = true,
    forceHideExpandColumn,
}: MHTableRowProps<I>) => {
    const defaultColumns = useMemo(<T extends MHTableBaseRow>(): MHTableColumnConfig<T>[] => {
        const onRowSelectReady = onRowSelect ? () => onRowSelect(row) : null;
        const predefinedColumns = [];
        const predefinedRowSelectionColumn: MHTableColumnConfig<T> = {
            rowActionDisabled: true,
            cellStyle: styles.metaCell,
            key: 'checkbox-col',
            content: () => (
                <MHCheckbox
                    inputProps={{role: `tableRowCheckbox_${row.id}`}}
                    disabled={disableSelect && disableSelect(row)}
                    checked={selected}
                    onChange={onRowSelectReady}
                />
            ),
        };
        const predefinedRowExpandColumn: MHTableColumnConfig<T> = {
            rowActionDisabled: true,
            cellStyle: styles.metaCell,
            key: 'expanded-col',
            noData: null,
            content: () =>
                isExpandable && (
                    <div data-testid={row?.id} className={styles.rowExpandControls} onClick={() => onExpand(row)}>
                        <ArrowRightIcon
                            className={classnames(styles.arrowIcon, {
                                [styles.fullInfoVisible]: isExpanded,
                            })}
                        />
                    </div>
                ),
        };

        if (onRowSelectReady) predefinedColumns.push(predefinedRowSelectionColumn);
        if (expandableContent && !forceHideExpandColumn) predefinedColumns.push(predefinedRowExpandColumn);

        return predefinedColumns;
    }, [
        disableSelect,
        expandableContent,
        isExpanded,
        onExpand,
        onRowSelect,
        row,
        selected,
        isExpandable,
        forceHideExpandColumn,
    ]);

    const cells = useMemo(() => {
        const totalCells = [...defaultColumns, ...columns];
        return totalCells.map((column) => {
            const disableRowAction = onRowClick && column.rowActionDisabled;
            const columnKey = getUniqueKey(column);
            const isDefaultKeys = columnKey === 'expanded-col' || columnKey === 'checkbox-col';

            const cellData = column.content(row);

            return (
                <MHTableCell
                    cellStyle={`${!isDefaultKeys ? externalCellStyles : ''} ${column.cellStyle}`}
                    key={columnKey}
                    disableRowAction={disableRowAction}
                    noData={column.noData}
                >
                    {cellData}
                </MHTableCell>
            );
        });
    }, [columns, defaultColumns, externalCellStyles, onRowClick, row]);

    return (
        <>
            <MHTableRowElement
                row={row}
                selected={selected || isExpanded}
                hover={hover}
                setExternalStyles={externalRowStyles}
                onRowClick={onRowClick && (() => onRowClick(row))}
            >
                {cells}
            </MHTableRowElement>
            {expandableContent && isExpanded && (
                <ExpandedRow
                    isExpanded={isExpanded}
                    row={row}
                    syncExpandable={syncExpandable}
                    expandableContent={expandableContent}
                    colsLength={cells.length}
                />
            )}
        </>
    );
};

export const MHTableRow = memo(MHTableRowNotMemoized) as typeof MHTableRowNotMemoized;
