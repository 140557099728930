export const buildVisiblePages = (pagesArray, active) => {
    const numberOfPages = pagesArray.length;

    const firstPage = pagesArray.slice(0, 1);
    const firstFewPages = pagesArray.slice(0, active + 2);

    const lastPage = pagesArray.slice(-1);
    const lastFewPages = pagesArray.slice(active - 1);

    const activePageWithNeighbors = pagesArray.slice(active - 1, active + 2);

    const separator = '...';

    if (numberOfPages < 6) {
        return pagesArray;
    }

    // if the active page <= than 3 page
    if (active <= 2) {
        return [...firstFewPages, separator, ...lastPage];
    }

    // if the active page >= last 3 pages
    if (active >= numberOfPages - 3) {
        return [...firstPage, separator, ...lastFewPages];
    }

    return [...firstPage, separator, ...activePageWithNeighbors, separator, ...lastPage];
};
