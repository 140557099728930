import {ForwardedRef, forwardRef, memo} from 'react';
import * as React from 'react';
import ReactQuill, {Quill} from 'react-quill';
import InputLabel from '@mui/material/InputLabel';
import classnames from 'classnames';

import './theme.css';
import styles from './mhRichText.module.scss';

const Link = Quill.import('formats/link');
class CustomLink extends Link {
    static create(value: string) {
        const node = super.create(value);

        value = this.sanitize(value);
        if (!value.startsWith('https')) {
            value = `https://${value}`;
        }

        node.setAttribute('href', value);
        return node;
    }
}

Quill.register(CustomLink, true);

type MHRichTextProps = {
    fieldValue: string;
    onChangeHandler?: (value: string) => void;
    onBlurHandler?: () => void;
    isReadOnly?: boolean;
    label?: string | React.ReactNode;
    placeholder?: string;
    readOnlyClassName?: string;
    externalClassName?: string;
    externalLabelClassName?: string;
};

const MHRichText = memo<MHRichTextProps & {ref?: ForwardedRef<any>}>(
    forwardRef(
        (
            {
                label = '',
                isReadOnly = false,
                readOnlyClassName = '',
                fieldValue,
                onChangeHandler,
                onBlurHandler,
                externalClassName = '',
                externalLabelClassName = '',
                placeholder = '',
            }: MHRichTextProps,
            reactQuillRef
        ) => {
            const viewType = isReadOnly ? 'readOnlyView' : 'editorView';
            const inputId = label ? String(label).replace(' ', '').toLowerCase() : '';

            const config = {
                readOnlyView: {
                    labelStyles: classnames(styles.inputLabel, styles.isReadOnlyLabel, externalLabelClassName),
                    editorProps: {
                        theme: 'bubble',
                        value: fieldValue || '',
                        className: classnames(styles.richTextReadOnly, readOnlyClassName),
                        readOnly: true,
                        ...(onChangeHandler ? {onChange: onChangeHandler} : {}),
                        modules: {
                            clipboard: {
                                matchVisual: false,
                            },
                        },
                    },
                },
                editorView: {
                    labelStyles: styles.inputLabel,
                    editorProps: {
                        theme: 'snow',
                        value: fieldValue || '',
                        className: classnames(styles.richText, externalClassName),
                        modules: {
                            clipboard: {
                                matchVisual: false,
                            },
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike', 'clean'],
                                [{color: [] as string[]}],
                                [{list: 'bullet'}, {list: 'ordered'}],
                                ['link'],
                            ],
                        },
                        placeholder,
                        onChange: onChangeHandler || null,
                        bounds: '[data-text-editor="mh-rich-text"]',
                    },
                },
            };
            const {labelStyles, editorProps} = {...config[viewType]};

            // ReactQuill wrapped into <div> with onBlur, to fix that ReactQuill's onBlur event
            // does not trigger on interactive elements like buttons or checkboxes

            return (
                <>
                    {label && (
                        <InputLabel className={labelStyles} id={inputId} htmlFor={inputId}>
                            {label}
                        </InputLabel>
                    )}
                    <div
                        onBlur={onBlurHandler || null}
                        className={styles.onBlurWrapper}
                        data-text-editor="mh-rich-text"
                        aria-label={inputId}
                    >
                        <ReactQuill ref={reactQuillRef} {...editorProps} />
                    </div>
                </>
            );
        }
    )
);

MHRichText.displayName = 'MHRichText';
export default MHRichText;
