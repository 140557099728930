import {useCallback, useEffect, useRef, useState} from 'react';

import {MHTableBaseRow, RowSelectionActionType, SelectRowType} from '../shared/types';

type SelectedRowsMapType<Q extends string | number> = Partial<Record<string | number, Q>>;

export const useSelectHook = <T extends MHTableBaseRow>(
    selectedRows: SelectRowType<T>['selectedRows'],
    onRowSelect: SelectRowType<T>['onRowSelect']
) => {
    const selectedRowsMapInternalStorage = useRef<SelectedRowsMapType<T['id']>>({});
    const [selectedRowsMap, setSelectedRowsMap] = useState<SelectedRowsMapType<T['id']>>({});

    const onRowSelectInternal = useCallback(
        (row: T) => {
            if (onRowSelect) {
                let action: RowSelectionActionType = 'row_selected';
                const updatedLocalSelectedStorage = (id: T['id']) => {
                    if (selectedRowsMapInternalStorage.current[id]) {
                        delete selectedRowsMapInternalStorage.current[id];
                        action = 'row_unselected';
                    } else {
                        selectedRowsMapInternalStorage.current[id] = id;
                    }
                };

                updatedLocalSelectedStorage(row.id);

                const selectedRowsArray: T['id'][] = [];

                for (const rowId in selectedRowsMapInternalStorage.current) {
                    selectedRowsArray.push(selectedRowsMapInternalStorage.current[rowId]);
                }

                onRowSelect(selectedRowsArray, row.id, action);
            }
        },
        [onRowSelect]
    );

    useEffect(() => {
        const selectedRowsNew: SelectedRowsMapType<T['id']> = {};

        selectedRows.forEach((rowId) => {
            selectedRowsNew[rowId] = rowId;
        });

        selectedRowsMapInternalStorage.current = selectedRowsNew;
        setSelectedRowsMap(selectedRowsNew);
    }, [selectedRows]);

    return {onRowSelectInternal: onRowSelect ? onRowSelectInternal : undefined, selectedRowsMap};
};
