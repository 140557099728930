import {MouseEventHandler, ReactNode} from 'react';
import {Button} from '@mui/material';
import classnames from 'classnames';

import styles from './mhTextButton.module.scss';

enum BtnType {
    PrimaryPositive = 'primary-positive',
    SecondaryPositive = 'secondary-positive',
    PrimaryDanger = 'primary-danger',
    CancelDiscard = 'cancel-discard',
}

type ButtonType = `${BtnType}`;

type MHTextButtonProps = {
    btnType: ButtonType;
    text: string;
    margin?: string;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    disabled?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    id?: string;
    type?: 'submit' | 'button';
};

const btnClasses = {
    label: styles.gap,
};

export const MHTextButton = ({
    btnType,
    text,
    margin = '0',
    onClick,
    disabled,
    startIcon,
    endIcon,
    id,
    type,
}: MHTextButtonProps) => {
    const classBtnTypeMapper: {[key: string]: string} = {
        [BtnType.PrimaryPositive]: styles.textTealBtn,
        [BtnType.PrimaryDanger]: styles.textRedBtn,
        [BtnType.SecondaryPositive]: styles.textBlueBtn,
        [BtnType.CancelDiscard]: styles.textWhiteBtn,
    };

    const classNameBtnType = classBtnTypeMapper[btnType];

    return (
        <Button
            role="button"
            style={{
                margin,
            }}
            type={type}
            id={id}
            onClick={onClick}
            disabled={disabled}
            className={classnames(classNameBtnType)}
            classes={btnClasses}
            startIcon={startIcon}
            endIcon={endIcon}
        >
            {text}
        </Button>
    );
};
