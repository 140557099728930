import React, {useEffect, useRef} from 'react';

type MHHandleOutsideClick = {
    action?: (value?: boolean) => void;
    className?: string;
    children: React.ReactNode;
};

const HandleOutsideClick = ({action, className, children}: MHHandleOutsideClick) => {
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const wrapperRef = useRef(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
            action && action();
        }
    };

    return (
        <div ref={wrapperRef} className={className}>
            {children}
        </div>
    );
};

export default HandleOutsideClick;
