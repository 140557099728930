import {useCallback, useMemo} from 'react';
import {ActionMeta, GroupBase, MultiValue, OnChangeValue, OptionsOrGroups, PropsValue} from 'react-select';

import {AllOption, DefaultOption, NormalisedOptions, UseMHSelectOptionsProps} from './types';
import {
    checkIfOptionIsSelected,
    computeInnerValue,
    computeNewValue as computeNewValueUtil,
    normaliseGroup as normaliseGroupUtil,
    normaliseOption,
    normaliseValue,
} from './utils';

export const useMHSelectOptions = <
    Option extends DefaultOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>({
    value: selectedOptions,
    isMulti,
    options,
    selectAllOptionLabel,
    selectAllOptionsInGroupsLabeling = [],
    showSelectAllOption,
    optionGroupsWithSelectAll,
    areSelectAllInGroupOptionsShown,
    largeDataSet,
    onChangeHandler,
}: UseMHSelectOptionsProps<Option, IsMulti, Group>) => {
    const isSelectAllOptionShown = !largeDataSet && showSelectAllOption && isMulti;

    const selectAllOption = useMemo<AllOption>(() => {
        return {
            value: 'All',
            label: selectAllOptionLabel || 'All',
        };
    }, [selectAllOptionLabel]);

    const computeGroupSelectAllOption = useCallback(
        (option: Group): AllOption | undefined => {
            if (!areSelectAllInGroupOptionsShown) return;

            // optionGroupsWithSelectAll === undefined means "select all" options are shown in all groups
            const isSelectAllInGroupShown = optionGroupsWithSelectAll?.includes(option.label) ?? true;
            if (!isSelectAllInGroupShown) return;

            const groupAllLabelConfig = selectAllOptionsInGroupsLabeling.find(
                (groupLableling) => groupLableling.groupLabel === option.label
            );

            const defaultAllLabel = `All ${option.label}`;

            return {
                label: groupAllLabelConfig?.allOptionLabel || defaultAllLabel,
                value: defaultAllLabel,
            };
        },
        [areSelectAllInGroupOptionsShown, optionGroupsWithSelectAll, selectAllOptionsInGroupsLabeling]
    );

    type NormalisedInstanceOptions = NormalisedOptions<Option, Group>;

    const normaliseGroup = useCallback(
        (group: Group, optionMap: NormalisedInstanceOptions) => {
            const groupSelectAllOption = computeGroupSelectAllOption(group);
            return normaliseGroupUtil(group, optionMap, groupSelectAllOption);
        },
        [computeGroupSelectAllOption]
    );

    const normalisedOptions = useMemo(() => {
        const defaultNormalisedOptions: NormalisedInstanceOptions = {
            groups: new Map(),
            options: new Map(),
            selectAllOptions: new Map(),
        };

        return options.reduce<NormalisedInstanceOptions>((optionMap, option) => {
            if ('options' in option) {
                return normaliseGroup(option, optionMap);
            }

            return normaliseOption(optionMap, option);
        }, defaultNormalisedOptions);
    }, [normaliseGroup, options]);

    const normalisedValue = useMemo(() => {
        if (!isMulti) return;

        const selected = (selectedOptions as Option[]) || [];
        return normaliseValue(selected, normalisedOptions);
    }, [isMulti, selectedOptions, normalisedOptions]);

    type OptionOrAll = Option | AllOption;

    const optionsWithAllOptionInGroups = useMemo<OptionsOrGroups<OptionOrAll, GroupBase<OptionOrAll>>>(() => {
        if (!isMulti) return options;

        if (!areSelectAllInGroupOptionsShown) return options;

        return options.map<Option | GroupBase<OptionOrAll>>((option) => {
            if (!('options' in option)) return option;

            const {groupSelectAllOptionValue} = normalisedOptions.groups.get(option.label);

            const groupSelectAllOption = normalisedOptions.selectAllOptions.get(groupSelectAllOptionValue);
            if (!groupSelectAllOption) return option;

            return {
                ...option,
                options: [groupSelectAllOption.option, ...option.options],
            };
        });
    }, [isMulti, options, areSelectAllInGroupOptionsShown, normalisedOptions]);

    const optionsWithAllOption = useMemo(() => {
        if (isSelectAllOptionShown) {
            return [selectAllOption, ...optionsWithAllOptionInGroups];
        }

        return optionsWithAllOptionInGroups;
    }, [optionsWithAllOptionInGroups, selectAllOption, isSelectAllOptionShown]);

    const computeNewValue = (
        optionsInField: OnChangeValue<OptionOrAll, IsMulti>,
        actionMeta: ActionMeta<OptionOrAll>
    ): PropsValue<Option> => {
        if (!isMulti) {
            return optionsInField as Option;
        }

        return computeNewValueUtil(
            optionsInField as Option[],
            selectedOptions as OptionOrAll[],
            normalisedOptions,
            normalisedValue,
            actionMeta,
            selectAllOption,
            isSelectAllOptionShown
        ) as MultiValue<Option>;
    };

    const onChange = (
        optionsInField: OnChangeValue<OptionOrAll, IsMulti>,
        actionMeta: ActionMeta<OptionOrAll>
    ): void => {
        const valueToSet = computeNewValue(optionsInField, actionMeta);
        onChangeHandler(valueToSet as OnChangeValue<Option, IsMulti>, actionMeta as ActionMeta<Option>);
    };

    //isOptionSelected is used to detect what option is selected
    //and is specifically used for cases with option 'All' in order to select all options of that option is selected
    const isOptionSelected = useMemo(() => {
        if (!isMulti || !selectedOptions) return;

        return (option: OptionOrAll): boolean => {
            return checkIfOptionIsSelected(
                option,
                selectAllOption,
                isSelectAllOptionShown,
                normalisedOptions,
                normalisedValue
            );
        };
    }, [isMulti, selectedOptions, selectAllOption, normalisedOptions, normalisedValue, isSelectAllOptionShown]);

    const innerValue = useMemo(() => {
        // if selectedOptions/value is null or undefined, let it stay that way because it's handled specifically
        if (!isMulti || !selectedOptions) return selectedOptions;

        const selected = selectedOptions as Option[];
        return computeInnerValue(selected, normalisedOptions, normalisedValue, selectAllOption, isSelectAllOptionShown);
    }, [isMulti, selectedOptions, selectAllOption, normalisedOptions, normalisedValue, isSelectAllOptionShown]);

    return {
        options: optionsWithAllOption,
        onChange,
        isOptionSelected,
        value: innerValue,
    };
};
