import TablePagination from '@mui/material/TablePagination';

import {MHPaginationActions} from './MHPaginationActions';
import {MHPaginationProps} from './types';

import styles from './mhPagination.module.scss';

export const MHPagination = ({
    count,
    size,
    page,
    onPageChange,
    onChangeRowsPerPage,
    rowsPerPageOptions = [10, 25, 50],
}: MHPaginationProps) => (
    <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={size}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={({count, page, rowsPerPage, onPageChange}) => (
            <MHPaginationActions {...{count, page, size: rowsPerPage, onPageChange}} />
        )}
        labelDisplayedRows={() => null}
        classes={{
            root: styles.paginationRoot,
            select: styles.paginationInput,
            selectLabel: styles.paginationCaption,
            displayedRows: styles.paginationCaption,
        }}
    />
);
