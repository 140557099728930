import {ReactNode} from 'react';
import TableCell from '@mui/material/TableCell';

import {MHSortControls, MHTableSortableConfig} from '@/components/base';

import styles from './mhTableHeadCell.module.scss';

type HeaderCellProps = {
    overrideStyles?: (obj: unknown) => typeof obj;
    sortKey?: string;
    sortable?: MHTableSortableConfig;
    children?: ReactNode;
};

export const MHTableHeadCell = ({children, sortable, sortKey}: HeaderCellProps) => {
    const {setSorting, sortBy, sortOrder} = sortable || {};
    return (
        <TableCell className={styles.tableCell}>
            {sortable && sortKey ? (
                <MHSortControls setSorting={setSorting} sortBy={sortBy} sortOrder={sortOrder} sortKey={sortKey}>
                    {children}
                </MHSortControls>
            ) : (
                children
            )}
        </TableCell>
    );
};
