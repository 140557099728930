import {MouseEvent} from 'react';
import Avatar from '@mui/material/Avatar';

type MHAvatarProps = {
    alt: string;
    customStyles: object;
    variant?: 'circular' | 'rounded' | 'square';
    handleClick?: (event: MouseEvent) => void;
};

export default function MHAvatar({alt, customStyles, variant, handleClick}: MHAvatarProps) {
    const array = alt.split('/');
    const matches = array.map((text) => text.slice(0, 1));
    const altString = matches.join('').toUpperCase();

    return (
        <Avatar alt={alt} variant={variant} onClick={handleClick} classes={customStyles}>
            {altString}
        </Avatar>
    );
}

MHAvatar.defaultProps = {
    variant: 'circular',
    handleClick: null,
};
