import {ReactNode} from 'react';
import {Typography} from '@mui/material';

import colors from '@/styles/colors_styleguide.module.scss';

import {MHTypographyColor, MHTypographyVariant} from './types';

import styles from './mhTypography.module.scss';

type MHTypographyProps = {
    children: ReactNode;
    variant: MHTypographyVariant;

    margin?: string | number;
    marginLeft?: string | number;
    marginRight?: string | number;
    marginTop?: string | number;
    marginBottom?: string | number;
    padding?: string | number;
    paddingLeft?: string | number;
    paddingRight?: string | number;
    paddingTop?: string | number;
    paddingBottom?: string | number;
    /**
     * If true, the text will not wrap, but instead will truncate with a text overflow ellipsis.
     * Note that text overflow can only happen if element has width or maxWidth specified.
     */
    noWrap?: boolean;
    zIndex?: number;

    maxWidth?: number | string;
    minWidth?: number | string;
    width?: number | string;

    position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;

    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    color?: MHTypographyColor;
    bgcolor?: MHTypographyColor;

    textDecorationLine?: 'inherit' | 'initial' | 'line-through' | 'none' | 'overline' | 'underline';
    textDecorationStyle?: 'inherit' | 'initial' | 'dashed' | 'dotted' | 'double' | 'solid' | 'wavy';
    overflowWrap?: 'normal' | 'anywhere' | 'break-word';
    /**
     * If true, 'span' will be used to overwrite current variant
     */
    isInline?: boolean;
};

export const MHTypography = ({
    children,
    color = 'black_100',
    variant,
    bgcolor,
    textDecorationLine,
    textDecorationStyle,
    isInline,
    overflowWrap,
    ...other
}: MHTypographyProps) => {
    const variantMapping = {
        headlineHero: 'h1',
        headline1: 'h1',
        headline2: 'h2',
        headline3: 'h3',
        headline4: 'h4',
        headline5: 'h5',
        headline6: 'h6',
        eyebrowM: 'h6',
        eyebrowS: 'h6',
        bodyL: 'p',
        bodyM: 'p',
        bodyS: 'p',
        labelL: 'p',
        labelM: 'p',
        labelS: 'p',
    };

    return (
        <Typography
            className={styles[variant]}
            variantMapping={variantMapping}
            sx={{textDecorationLine, textDecorationStyle, overflowWrap}}
            color={colors[color]}
            bgcolor={colors[bgcolor]}
            variant={variant}
            component={isInline ? 'span' : undefined}
            {...other}
        >
            {children}
        </Typography>
    );
};
