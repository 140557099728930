import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {LoggingActions} from '@/constants/loggingActions';

const ErrorBoundarySlice = createSlice({
    name: 'errorBoundaryReducer',
    initialState: {},
    reducers: {
        logError: (state, _action: PayloadAction<{message: any; actionName: LoggingActions}>) => state,
    },
});
const {reducer, actions} = ErrorBoundarySlice;

export const {logError} = actions;

export default reducer;
