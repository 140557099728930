import {Key, useCallback} from 'react';
import TableBody from '@mui/material/TableBody';

import {MHTableRow} from '../MHTableRow';
import {MHTableBaseRow, MHTableBaseType} from '../shared/types';
import {useExpandHook} from './useExpandHook';
import {useSelectHook} from './useSelectHook';

export const MHTableBody = <T extends MHTableBaseRow>({
    expandableContent,
    expandedRows,
    syncExpandable,
    onExpand,
    isSingleExpand,
    isRowExpandable,
    forceHideExpandColumn,
    data = [],
    selectedRows = [],
    onRowSelect,
    disableSelect,
    columns,
    customRow,
    onRowClick,
    hover = true,
    externalCellStyles = '',
    externalRowStyles,
}: MHTableBaseType<T>) => {
    const {expandedRowsInternal, toggleExpand} = useExpandHook(isSingleExpand, isRowExpandable);
    const {selectedRowsMap, onRowSelectInternal} = useSelectHook(selectedRows, onRowSelect);

    const mapOfExtendedRows = expandedRows || expandedRowsInternal;

    const onExpandHandler = useCallback(
        (row) => (onExpand ? onExpand(row) : toggleExpand(row.id)),
        [onExpand, toggleExpand]
    );

    const tableRows = data.map((row) => {
        const isSelected = !!selectedRowsMap[row.id];
        const isExpanded = !!mapOfExtendedRows[row.id];
        const isExpandable = isRowExpandable ? !!isRowExpandable(row) : true;
        const RowComponent = customRow || MHTableRow;
        return (
            <RowComponent
                isExpanded={isExpanded}
                onExpand={onExpandHandler}
                isExpandable={isExpandable}
                externalCellStyles={externalCellStyles}
                expandableContent={expandableContent}
                syncExpandable={syncExpandable}
                forceHideExpandColumn={forceHideExpandColumn}
                columns={columns}
                disableSelect={disableSelect}
                onRowClick={onRowClick}
                onRowSelect={onRowSelectInternal}
                key={row?.id as Key}
                row={row}
                hover={hover}
                selected={isSelected}
                externalRowStyles={externalRowStyles}
            />
        );
    });

    return <TableBody>{tableRows}</TableBody>;
};
