import {useState} from 'react';
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import classnames from 'classnames';
import moment, {Moment} from 'moment';

import {getDateTimeFormats, isBefore} from '@/utils/timeFormatter';

import {MHRegularButton} from '../buttons';
import HandleOutsideClick from '../MHHandleOutsideClick';

import styles from './styles.module.scss';

const {DATE_FORMAT} = getDateTimeFormats();

type MHDatePickerContentProps = {
    date: string;
    minDate?: string;
    maxDate?: string;
    hideToday?: boolean;
    action?: () => void;
    moveToToday?: () => void;
    onChangeHandler: (value: string) => void;
    isOutsideRange?: (day: Moment) => boolean;
};

const MHDatePickerContent = ({
    date,
    action,
    minDate,
    maxDate,
    hideToday,
    moveToToday,
    isOutsideRange,
    onChangeHandler,
}: MHDatePickerContentProps) => {
    const checkValue = (value: string) => {
        const regExp = /^[0-9/]*$/g;
        return regExp.test(value);
    };

    const handleChangeInput = (date: Moment | null) => {
        const formattedDate = moment(date).format(DATE_FORMAT);

        if (!checkValue(formattedDate)) {
            return;
        }

        onChangeHandler(formattedDate);
    };

    const checkHighlightedDates = (date: Moment) => moment().isSame(date, 'day');

    const checkBlockedDates = (date: Moment) => {
        const isValidForMinDate = minDate
            ? moment(minDate, DATE_FORMAT).subtract(1, 'd').isBefore(moment(date, DATE_FORMAT), 'day')
            : true;
        const isValidForMaxDate = maxDate
            ? moment(maxDate, DATE_FORMAT).add(1, 'd').isAfter(moment(date, DATE_FORMAT), 'day')
            : true;

        return !(isValidForMinDate && isValidForMaxDate);
    };

    const today = moment().format(DATE_FORMAT);
    const todayIsOutOfInterval =
        (minDate && isBefore(today, minDate, DATE_FORMAT)) || (maxDate && isBefore(maxDate, today, DATE_FORMAT));
    const hideTodayButton = todayIsOutOfInterval || hideToday;
    const dateToSet = todayIsOutOfInterval ? minDate : date;
    const [selectedMonth, setSelectedMonth] = useState(null);
    const isPrevMonthButtonDisabled = minDate ? moment(selectedMonth).isSame(minDate, 'month') : false;
    const isNextMonthButtonDisabled = maxDate ? moment(selectedMonth).isSame(maxDate, 'month') : false;

    return (
        <HandleOutsideClick action={action}>
            {/* We need to add an empty onFocusChange function prop as it is mandatory in DayPickerSingleDateController */}
            <DayPickerSingleDateController
                focused
                onFocusChange={() => null}
                hideKeyboardShortcutsPanel
                initialVisibleMonth={null}
                isOutsideRange={isOutsideRange}
                onDateChange={handleChangeInput}
                isDayBlocked={checkBlockedDates}
                isDayHighlighted={checkHighlightedDates}
                date={moment(dateToSet, DATE_FORMAT).isValid() ? moment(dateToSet, DATE_FORMAT) : null}
                ref={(dayPicker) => {
                    setSelectedMonth(dayPicker?.state?.currentMonth);
                }}
                onPrevMonthClick={setSelectedMonth}
                onNextMonthClick={setSelectedMonth}
                navPrev={
                    <ArrowRightAltIcon
                        className={classnames(styles.arrowIcon, styles.leftArrowIcon, {
                            [styles.disabled]: isPrevMonthButtonDisabled,
                        })}
                    />
                }
                navNext={
                    <ArrowRightAltIcon
                        className={classnames(styles.arrowIcon, styles.rightArrowIcon, {
                            [styles.disabled]: isNextMonthButtonDisabled,
                        })}
                    />
                }
            />
            {!hideTodayButton && (
                <div className={styles.todayButtonWrapper}>
                    <MHRegularButton text="Today" onClick={moveToToday} btnType="primary-positive" btnSize="small" />
                </div>
            )}
        </HandleOutsideClick>
    );
};

export default MHDatePickerContent;
