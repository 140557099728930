import {ReactNode, useMemo} from 'react';
import {TableHead} from '@mui/material';

import {MHCheckbox, MHTableRowElement} from '@/components/base';

import {GetColumnTitleType, MHTableSortableConfig} from '../shared/types';
import {MHTableHeadCell} from './MHTableHeadCell';

import styles from './mhTableHead.module.scss';

type MHTableHeadProps = {
    reserveEmptyOnExpandColumn?: boolean;
    reserveEmptyOnSelectColumn?: boolean;
    onSelectAll?: () => void;
    isAllSelected?: boolean;
    isNotAllRowsSelected?: boolean;
    columnHeaderConfig: {
        title: ReactNode | GetColumnTitleType;
        sortKey: string;
    }[];
    sortable?: MHTableSortableConfig;
    overrideStyles?: (obj: string) => string;
    overrideHeaderCellStyles?: (obj: unknown) => typeof obj;
};

export const MHTableHead = ({
    sortable,
    onSelectAll,
    isAllSelected,
    isNotAllRowsSelected,
    columnHeaderConfig,
    reserveEmptyOnSelectColumn,
    reserveEmptyOnExpandColumn,
}: MHTableHeadProps) => {
    const renderHeaderCells = useMemo(
        () => () =>
            columnHeaderConfig.map((column, index) => {
                const {sortKey, title} = column;

                return (
                    <MHTableHeadCell key={index} sortable={sortable} sortKey={sortKey}>
                        {typeof title === 'function' ? title(sortable, sortKey) : title}
                    </MHTableHeadCell>
                );
            }),
        [columnHeaderConfig, sortable]
    );
    const tableHeadStyles = styles.tableHead;

    return (
        <TableHead className={tableHeadStyles}>
            <MHTableRowElement hover={false}>
                {onSelectAll && (
                    <MHTableHeadCell>
                        <MHCheckbox
                            indeterminate={isNotAllRowsSelected}
                            checked={isAllSelected}
                            onChange={() => onSelectAll()}
                        />
                    </MHTableHeadCell>
                )}
                {reserveEmptyOnSelectColumn && !onSelectAll && <MHTableHeadCell />}
                {reserveEmptyOnExpandColumn && <MHTableHeadCell />}
                {renderHeaderCells()}
            </MHTableRowElement>
        </TableHead>
    );
};
