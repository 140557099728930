import {Checkbox, CheckboxProps, FormControlLabel} from '@mui/material';

import {MHTypography, MHTypographyColor} from '@/components/base/MHTypography';

import styles from './mhCheckbox.module.scss';

type MHCheckboxProps = {
    label?: string;
    testId?: string;
} & Pick<CheckboxProps, 'indeterminate' | 'checked' | 'onChange' | 'disabled' | 'inputProps'>;

const checkboxStyles = {
    root: styles.mhCheckboxRoot,
    checked: styles.mhCheckboxChecked,
    indeterminate: styles.mhCheckboxIndeterminate,
    disabled: styles.mhCheckboxDisabled,
};

const Colors = {
    Checked: 'black_100',
    Unchecked: 'black_80',
    CheckedDisabled: 'black_60',
    UncheckedDisabled: 'black_40',
} as const satisfies Record<string, MHTypographyColor>;

export const MHCheckbox = ({
    indeterminate,
    checked,
    onChange,
    disabled,
    inputProps,
    label,
    testId,
}: MHCheckboxProps) => {
    const renderCheckbox = () => (
        <Checkbox
            data-testid={testId}
            inputProps={inputProps}
            classes={checkboxStyles}
            indeterminate={indeterminate}
            disableRipple
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
    );

    if (label) {
        const getColor = (): MHTypographyColor => {
            const isFilled = checked || indeterminate;

            if (disabled) {
                return isFilled ? Colors.CheckedDisabled : Colors.UncheckedDisabled;
            }

            return isFilled ? Colors.Checked : Colors.Unchecked;
        };

        return (
            <FormControlLabel
                classes={{root: styles.formControlLabelRoot}}
                disabled={disabled}
                control={renderCheckbox()}
                label={
                    <MHTypography variant="bodyM" color={getColor()} marginLeft="8px">
                        {label}
                    </MHTypography>
                }
            />
        );
    }

    return renderCheckbox();
};
