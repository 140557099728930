import {MouseEventHandler, ReactNode} from 'react';
import {Button} from '@mui/material';
import classnames from 'classnames';

import {MHLoader} from '@/components/base';

import styles from './mhRegularButton.module.scss';

enum BtnType {
    PrimaryPositive = 'primary-positive',
    PrimaryDanger = 'primary-danger',
    SecondaryPositive = 'secondary-positive',
    SecondaryDanger = 'secondary-danger',
    CancelDiscard = 'cancel-discard',
}

enum BtnSize {
    Medium = 'medium',
    Small = 'small',
}

type ButtonType = `${BtnType}`;
type ButtonSize = `${BtnSize}`;

type MHRegularButtonProps = {
    btnType: ButtonType;
    text: string;
    btnSize?: ButtonSize;
    margin?: string;
    width?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    loading?: boolean;
    disabled?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    id?: string;
    type?: 'submit' | 'button';
    wrapLabel?: boolean;
    testId?: string;
};

const btnClasses = {
    label: styles.gap,
    startIcon: styles.icon,
    endIcon: styles.icon,
};

export const MHRegularButton = ({
    wrapLabel,
    btnType,
    text,
    btnSize = 'medium',
    margin = '0',
    width,
    onClick,
    loading,
    disabled,
    startIcon,
    endIcon,
    id,
    type = 'button',
    testId,
}: MHRegularButtonProps) => {
    const classBtnTypeMapper: {[key: string]: string} = {
        [BtnType.PrimaryPositive]: styles.primaryPositiveBtn,
        [BtnType.PrimaryDanger]: styles.primaryDangerBtn,
        [BtnType.SecondaryPositive]: styles.secondaryPositiveBtn,
        [BtnType.SecondaryDanger]: styles.secondaryDangerBtn,
        [BtnType.CancelDiscard]: styles.cancelDiscardBtn,
    };
    const classBtnSizeMapper: {[key: string]: string} = {
        [BtnSize.Medium]: styles.mediumBtn,
        [BtnSize.Small]: styles.smallBtn,
    };
    const classNameBtnType = classBtnTypeMapper[btnType];
    const classNameBtnSize = classBtnSizeMapper[btnSize];

    return (
        <Button
            style={{
                margin,
                width,
            }}
            id={id}
            size={btnSize}
            onClick={onClick}
            disabled={loading || disabled}
            classes={btnClasses}
            className={classnames(classNameBtnType, classNameBtnSize, {[styles.wrapLabel]: wrapLabel})}
            startIcon={startIcon}
            endIcon={endIcon}
            type={type}
            data-testid={testId}
        >
            {text}
            {loading && (
                <MHLoader data-testid="loader" color="primary" size={'20px'} rootClassName={styles.loaderClass} />
            )}
        </Button>
    );
};
