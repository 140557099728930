import {memo, useCallback, useEffect, useMemo} from 'react';
import {Table, TableContainer} from '@mui/material';
import classNames from 'classnames';

import {MHLoader, MHPagination} from '@/components/base';

import {MHTableBaseRow, MHTableProps, RowSelectionActionType} from './shared/types';
import {MHTableBody} from './MHTableBody';
import {MHTableHead} from './MHTableHead';

import styles from './mhTable.module.scss';

const MHTableNotMemoized = <R extends MHTableBaseRow>({
    id,
    data,
    columns,
    noData,

    onRowClick,
    hover = true,
    customRow,

    selectedRows = [],
    onRowSelect,
    showSelectAll = true,
    disableSelect,

    expandableContent,
    syncExpandable,
    expandedRows,
    isSingleExpand,
    onExpand,
    isRowExpandable,
    forceHideExpandColumn,

    pagination,
    hidePaging = false,
    stickyHeader = false,
    loading = false,

    sortable,

    externalRowStyles,
    externalCellStyles = '',
    tableContainerRootStyles = '',
}: MHTableProps<R>) => {
    useEffect(() => {
        const existingDeps = [expandedRows, onExpand].filter((el) => !!el);
        if (isSingleExpand && !!existingDeps.length) throw 'Can not use expandedRows or onExpand with isSingleExpand';
        if (existingDeps.length !== 0 && existingDeps.length !== 2)
            throw 'expandedRows and onExpand can not be used without one another';
    }, [onExpand, expandedRows, isSingleExpand]);

    const filteredData = useMemo(
        () => (disableSelect ? data.filter((row) => !disableSelect(row)) : data),
        [data, disableSelect]
    );

    const allRowsSelected = useMemo(
        () => (showSelectAll ? selectedRows.length === filteredData.length : false),
        [filteredData.length, showSelectAll, selectedRows.length]
    );

    const onAllSelect = useCallback(() => {
        if (showSelectAll && onRowSelect) {
            let allRowIds: R['id'][] = [];
            let action: RowSelectionActionType = 'all_unselected';
            if (!allRowsSelected) {
                allRowIds = filteredData.map((el) => el.id);
                action = 'all_selected';
            }
            onRowSelect(allRowIds, null, action);
        }
    }, [filteredData, allRowsSelected, onRowSelect, showSelectAll]);

    const columnDataForTableHead = useMemo(() => columns.map(({sortKey, title}) => ({sortKey, title})), [columns]);

    const loadedAndNoData = !data.length && !loading;
    const loadedAndDataExist = !!data.length && !loading;

    return (
        <TableContainer className={classNames(styles.tableContainerRoot, tableContainerRootStyles)}>
            {loading && <MHLoader rootClassName={styles.tableLoader} />}
            {loadedAndNoData && <div className={styles.noDataMessage}>{noData || 'No data'}</div>}
            {loadedAndDataExist && (
                <Table stickyHeader={stickyHeader} id={id}>
                    <MHTableHead
                        isAllSelected={allRowsSelected}
                        isNotAllRowsSelected={!!selectedRows.length && !allRowsSelected}
                        onSelectAll={showSelectAll && onRowSelect ? onAllSelect : undefined}
                        reserveEmptyOnSelectColumn={!!onRowSelect}
                        reserveEmptyOnExpandColumn={!!expandableContent && !forceHideExpandColumn}
                        columnHeaderConfig={columnDataForTableHead}
                        sortable={sortable}
                    />
                    <MHTableBody
                        expandableContent={expandableContent}
                        syncExpandable={syncExpandable}
                        selectedRows={selectedRows}
                        onRowSelect={onRowSelect}
                        disableSelect={disableSelect}
                        data={data}
                        hover={hover}
                        onRowClick={onRowClick}
                        customRow={customRow}
                        columns={columns}
                        externalRowStyles={externalRowStyles}
                        externalCellStyles={externalCellStyles}
                        expandedRows={expandedRows}
                        isSingleExpand={isSingleExpand}
                        onExpand={onExpand}
                        isRowExpandable={isRowExpandable}
                        forceHideExpandColumn={forceHideExpandColumn}
                    />
                </Table>
            )}
            {!!data.length && pagination?.count > 5 && !hidePaging && <MHPagination {...pagination} />}
        </TableContainer>
    );
};

export const MHTable = memo(MHTableNotMemoized) as typeof MHTableNotMemoized;
