export enum LoggingActions {
    appInitialization = 'ui.app.initialization',
    appLogout = 'ui.logout',
    pageCrashError = 'ui.error.page-crash',

    websocketInitialization = 'ui.websocket.initialization',
    websocketDisconnect = 'ui.websocket.disconnect',
    //amazonConnect
    amazonConnectInitialization = 'ui.amazon-connect.initialization',
    amazonConnectErrors = 'ui.amazon-connect.errors',
    amazonConnectStateChange = 'ui.amazon-connect.state-change',
    amazonConnectOnAcceptButton = 'ui.amazon-connect.accept-call',
    amazonConnectOnDeclineButton = 'ui.amazon-connect.decline-call',

    //Zoom
    zoomVideoOnAcceptButton = 'ui.zoom-video.accept-call',
    zoomVideoOnDeclineButton = 'ui.zoom-video.decline-call',
    zoomInitiateShortPolling = 'ui.zoom-initialized-short-polling',
    zoomStopShortPolling = 'ui.zoom-stop-short-polling',

    //Video
    videoOnAcceptButton = 'ui.video.accept-call',
    videoOnDeclineButton = 'ui.video.decline-call',
    initiateShortPolling = 'ui.initialized-short-polling',
    stopShortPolling = 'ui.stop-short-polling',

    //User
    navigationToPatientPage = 'ui.patient-page.navigate',
}
