import {memo, ReactNode, useMemo} from 'react';
import {TableRow} from '@mui/material';

import styles from './mhTableRowElement.module.scss';

type MHTableRowProps<I> = {
    hover?: boolean;
    row?: I;
    setExternalStyles?: (classes: Record<string, string>, row: I) => Record<string, string>;
    selected?: boolean;
    onRowClick?: (row: I) => void;
    children: ReactNode;
};
const MHTableRowNotMemoized = <I,>({
    row,
    selected = false,
    onRowClick,
    hover = true,
    setExternalStyles,
    children,
}: MHTableRowProps<I>) => {
    const tableRowStyles = useMemo(() => {
        const tableRowClasses = {
            selected: styles.rowExpanded,
            hover: styles.rowHover,
            root: `${styles.mhTableRow} ${onRowClick ? styles.rowClickable : ''}`,
        };
        return setExternalStyles ? setExternalStyles(tableRowClasses, row) : tableRowClasses;
    }, [onRowClick, row, setExternalStyles]);

    return (
        <TableRow
            selected={selected}
            classes={tableRowStyles}
            hover={hover}
            onClick={onRowClick ? () => onRowClick(row) : null}
        >
            {children}
        </TableRow>
    );
};

export const MHTableRowElement = memo(MHTableRowNotMemoized) as typeof MHTableRowNotMemoized;
