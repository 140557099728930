export const MHSortIconDescendingIcon = ({testId}: {testId?: string}) => (
    <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        transform="scale(-1 -1)"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={testId}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 6.5L5 0.5L0.5 6.5H9.5ZM9.5 9.5L5 15.5L0.5 9.5L9.5 9.5ZM8 10.25L5 14.25L2 10.25L8 10.25Z"
            fill="#0AA6A8"
        />
    </svg>
);
