import {
    ChangeEventHandler,
    FocusEventHandler,
    forwardRef,
    KeyboardEventHandler,
    ReactNode,
    useEffect,
    useState,
} from 'react';
import InputBase, {InputBaseProps} from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import classnames from 'classnames';

import MHLoader from '../MHLoader';

import styles from './styles.module.scss';

type PropsType = InputBaseProps & {
    showRequiredAsterisk?: boolean;
    inputStyles?: string;
    labelStyleClasses?: {[key: string]: string};
    type?: string;
    label?: string | ReactNode;
    subLabel?: string;
    value?: string;
    onChangeHandler?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    error?: boolean;
    errorMessage?: string;
    icon?: ReactNode;
    disabled?: boolean;
    rootClassName?: string;
    inputBaseClasses?: {[key: string]: string};
    extraPerformance?: boolean;
    externalErrorClassName?: string;
    loading?: boolean;
    placeholder?: string;
};

const MHTextField = forwardRef(
    (
        {
            showRequiredAsterisk,
            inputStyles, // class name with custom styles for input el
            labelStyleClasses = {},
            type = 'text',
            label,
            subLabel,
            value,
            onChange,
            onBlur,
            onKeyPress,
            error,
            errorMessage, // error message text
            icon,
            disabled,
            rootClassName, // class name with custom styles for root el,
            inputBaseClasses = {},
            extraPerformance, // enable memo option for field to improve perfomance
            externalErrorClassName,
            loading,
            placeholder,
            ...other
        }: PropsType,
        textFieldRef
    ) => {
        const [internalValue, setInternalValue] = useState('');

        useEffect(() => {
            if (extraPerformance) {
                setInternalValue(value);
            }
        }, [extraPerformance, value]);

        const val = extraPerformance ? internalValue : value;
        const changeHandlerextraPerformance = ({target}: {target: HTMLTextAreaElement | HTMLInputElement}) => {
            setInternalValue(target.value);
        };

        const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
            if (event.key.match(/[^0-9]/)) {
                event.preventDefault();
            }
            if (onKeyPress) onKeyPress(event);
        };

        const defaultLabelStyles = classnames(styles.label, {
            [styles.labelDisabled]: disabled,
            [styles.labelError]: errorMessage,
        });
        const labelClasses = {...labelStyleClasses};
        labelClasses['root'] = defaultLabelStyles + ` ${labelClasses.root || ''}`;

        const additionalNumericProps = type === 'number' ? {onKeyPress: handleKeyPress} : {};

        const inputId = other.id || other.name;

        return (
            <div className={`${styles.mhTextFieldRoot} ${rootClassName || ''}`}>
                {label && (
                    <InputLabel classes={labelClasses} htmlFor={inputId}>
                        {label} {showRequiredAsterisk && '*'}
                        {subLabel && <span className={styles.subLabel}>{subLabel}</span>}
                    </InputLabel>
                )}

                {!!icon && icon}

                <div className={styles.inputWrapper}>
                    <InputBase
                        inputRef={textFieldRef}
                        classes={{
                            root: `${styles.inputRoot} ${inputStyles || ''} ${inputBaseClasses.root || ''}`,
                            input: classnames(styles.defaultInputStyles, inputStyles, inputBaseClasses.input, {
                                [styles.inputError]: errorMessage || error,
                            }),
                            inputMultiline: `${inputBaseClasses.inputMultiline || ''}`,
                        }}
                        type={type}
                        value={val === null ? '' : val}
                        error={error}
                        disabled={disabled || loading}
                        {...other}
                        id={inputId}
                        onChange={extraPerformance ? changeHandlerextraPerformance : onChange}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        {...additionalNumericProps}
                    />

                    {loading && <MHLoader rootClassName={styles.loaderWrapper} className={styles.loadingIcon} />}
                </div>

                {errorMessage && (
                    <div
                        className={classnames(styles.errorMessage, {
                            [externalErrorClassName]: externalErrorClassName,
                        })}
                    >
                        <span>{errorMessage}</span>
                    </div>
                )}
            </div>
        );
    }
);

(MHTextField as React.NamedExoticComponent).displayName = 'MHTextField';
export default MHTextField;
