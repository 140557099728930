import {memo} from 'react';
import TablePagination from '@mui/material/TablePagination';

import PaginationActions from './PaginationActions';

import styles from '../styles/pagination/styles.module.scss';

const Pagination = ({
    pagination: {count, size, page, onChangePage, onChangeRowsPerPage},
    previousPage,
    rowsPerPage,
}) => (
    <TablePagination
        rowsPerPageOptions={rowsPerPage}
        component="div"
        count={count}
        rowsPerPage={size}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={(props) => <PaginationActions {...props} previousPage={previousPage} />}
        labelDisplayedRows={() => null}
        classes={{
            select: styles.paginationInput,
            selectLabel: styles.paginationCaption,
            displayedRows: styles.paginationCaption,
        }}
    />
);

const propsAreEqual = (prevProps, nextProps) =>
    prevProps.pagination.page === nextProps.pagination.page &&
    prevProps.pagination.size === nextProps.pagination.size &&
    prevProps.pagination.count === nextProps.pagination.count;

export default memo(Pagination, propsAreEqual);
