import {ReactNode, useMemo} from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';

import {MHSortIconAscendingIcon, MHSortIconDefaultIcon, MHSortIconDescendingIcon} from '@/assets/icons';
import {MHTableSortableConfig, MHTableSortOrder} from '@/components/base';

import styles from './mhSortControls.module.scss';

type SortHeaderCellProps = MHTableSortableConfig & {
    sortKey: string;
    children?: ReactNode;
};

type SortIconProps = {active: boolean; sortOrder: MHTableSortOrder};

const SortIcon = ({active, sortOrder}: SortIconProps) => {
    const sortIcon = useMemo(() => {
        if (!active) return <MHSortIconDefaultIcon testId="defaultSortIcon" />;
        return sortOrder === 'desc' ? (
            <MHSortIconDescendingIcon testId="descendingSortIcon" />
        ) : (
            <MHSortIconAscendingIcon testId="ascendingSortIcon" />
        );
    }, [active, sortOrder]);
    return <div className={styles.sortIconComponent}>{sortIcon}</div>;
};

export const MHSortControls = ({sortKey, setSorting, sortOrder, sortBy, children}: SortHeaderCellProps) => {
    const active = sortKey === sortBy;

    const onClickSortingHandler = () => {
        // new logic for potential use
        // let nextSortingOrder: MHTableSortOrder;
        // if (sortOrder === 'asc') nextSortingOrder = 'desc';
        // if (sortOrder === 'desc') nextSortingOrder = '';
        // if (sortOrder === '' || !active) nextSortingOrder = 'asc';

        // old logic
        let nextSortingOrder: MHTableSortOrder;
        if (sortOrder === 'desc') nextSortingOrder = '';
        if (sortOrder === '') nextSortingOrder = 'desc';
        if (!active) nextSortingOrder = '';

        setSorting(nextSortingOrder, sortKey);
    };

    return (
        <TableSortLabel
            className={styles.tableSortLabel}
            active={active}
            {...(sortOrder ? {direction: sortOrder} : {})}
            onClick={onClickSortingHandler}
            IconComponent={() => <SortIcon active={active} sortOrder={sortOrder} />}
        >
            {children}
        </TableSortLabel>
    );
};
