import {ActionMeta, GroupBase, MultiValue, PropsValue, StylesConfig} from 'react-select';

import {AllOption, DefaultOption, NormalisedGroup, NormalisedOptions, NormalisedValue} from './types';

// customStylesFn is used to style the select
// by using extendedCustomStyles we can add styling to he new Select element or adjust the styles of existing element
const customStylesFn = <Option, IsMulti extends boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
    extendedCustomStyles: StylesConfig<Option, IsMulti, Group>
) => {
    const defaultStyles: StylesConfig<Option, IsMulti, Group> = {
        menuPortal: (provided, props) => ({
            ...provided,
            zIndex: '1301 !important',
            ...(extendedCustomStyles?.menuPortal ? extendedCustomStyles?.menuPortal(provided, props) : {}),
        }),
        menu: (provided, props) => ({
            ...provided,
            zIndex: '3',
            ...(extendedCustomStyles?.menu ? extendedCustomStyles?.menu(provided, props) : {}),
        }),
        dropdownIndicator: (provided, props) => ({
            transform: props.selectProps.menuIsOpen && 'rotate(180deg)',
            padding: props.selectProps.menuIsOpen ? '0 10px 0 0' : '0 0 0 10px',
            ...provided,
            ...(extendedCustomStyles?.dropdownIndicator
                ? extendedCustomStyles?.dropdownIndicator(provided, props)
                : {}),
        }),
    };

    return {
        ...extendedCustomStyles,
        ...defaultStyles,
    };
};

const normaliseOption = <Option extends DefaultOption, Group extends GroupBase<Option>>(
    optionMap: NormalisedOptions<Option, Group>,
    option: Option,
    group?: Group
) => {
    optionMap.options.set(option.value, {
        option,
        groupLabel: group?.label,
    });

    return optionMap;
};

const normaliseGroup = <Option, Group extends GroupBase<Option>>(
    group: Group,
    optionMap: NormalisedOptions<Option, Group>,
    groupSelectAllOption: AllOption
) => {
    group.options.forEach((option) => {
        normaliseOption(optionMap, option, group);
    });

    optionMap.groups.set(group.label, {
        group,
        groupSelectAllOptionValue: groupSelectAllOption?.value,
    });

    if (groupSelectAllOption) {
        optionMap.selectAllOptions.set(groupSelectAllOption.value, {
            option: groupSelectAllOption,
            groupLabel: group.label,
        });
    }

    return optionMap;
};

const normaliseValue = <Option extends DefaultOption, Group extends GroupBase<Option>>(
    selectedOptions: Option[],
    normalisedOptions: NormalisedOptions<Option, Group>
) => {
    return selectedOptions.reduce<NormalisedValue<Option, Group>>(
        (valueMap, selectedOption) => {
            const normalisedOption = normalisedOptions.options.get(selectedOption.value) || {
                option: selectedOption,
                groupLabel: undefined,
            };

            valueMap.options.set(selectedOption.value, normalisedOption);

            const {groupLabel} = normalisedOption;
            if (!groupLabel) {
                return valueMap;
            }

            const normalisedGroup = normalisedOptions.groups.get(groupLabel);

            const normalisedValueGroupOptions = valueMap.groups.get(groupLabel)?.group.options || [];

            valueMap.groups.set(groupLabel, {
                ...normalisedGroup,
                group: {
                    ...normalisedGroup.group,
                    options: [...normalisedValueGroupOptions, selectedOption],
                },
            });

            return valueMap;
        },
        {groups: new Map(), options: new Map()}
    );
};

const computeNewValueWhenSelectAllIsClicked = <Option, Group extends GroupBase<Option>>(
    normalisedOptions: NormalisedOptions<Option, Group>,
    {action}: ActionMeta<Option>
): PropsValue<Option> => {
    if (action === 'select-option') {
        const allAvailableOptions: Option[] = [];

        normalisedOptions.options.forEach((normalisedOption) => {
            allAvailableOptions.push(normalisedOption.option);
        });

        return allAvailableOptions;
    }

    if (['deselect-option', 'remove-value'].includes(action)) {
        return [];
    }
};

const computeNewValueWhenSelectAllInGroupIsClicked = <Option extends AllOption>(
    groupLabel: string,
    selectedOptions: DefaultOption[],
    normalisedOptions: NormalisedOptions<DefaultOption, GroupBase<DefaultOption>>,
    normalisedValue: NormalisedValue<DefaultOption, GroupBase<DefaultOption>>,
    {action}: ActionMeta<Option>
): PropsValue<DefaultOption> => {
    const possibleGroupValues = normalisedOptions.groups.get(groupLabel).group.options;

    if (action === 'select-option') {
        if (!selectedOptions) return [...possibleGroupValues];

        const normalisedValueGroup = normalisedValue.groups.get(groupLabel);

        const areAllInGroupSelected = normalisedValueGroup?.group.options.length === possibleGroupValues.length;
        if (areAllInGroupSelected) {
            const optionsOutsideThisGroup = selectedOptions.filter((selectedOption) => {
                const isFromGroup = normalisedOptions.options.get(selectedOption.value).groupLabel === groupLabel;
                return !isFromGroup;
            });

            return [...optionsOutsideThisGroup];
        }

        const valuesToAdd = possibleGroupValues.filter((possibleValue) => {
            const isAlreadySelected = normalisedValue.options.has(possibleValue.value);
            return !isAlreadySelected;
        });

        return [...selectedOptions, ...valuesToAdd];
    }

    if (['deselect-option', 'remove-value'].includes(action)) {
        const optionsOutsideThisGroup = selectedOptions.filter((selectedOption) => {
            const isFromGroup = normalisedOptions.options.get(selectedOption.value).groupLabel === groupLabel;
            return !isFromGroup;
        });
        return optionsOutsideThisGroup;
    }
};

const computeNewValueWhenEverythingIsSelectedAndOptionIsClicked = <Option extends DefaultOption>(
    clickedOption: Option,
    normalisedOptions: NormalisedOptions<Option, GroupBase<Option>>,
    normalisedValue: NormalisedValue<Option, GroupBase<Option>>
): MultiValue<DefaultOption> => {
    const notClickedOptions: Option[] = [];

    const clickedValue = clickedOption.value;
    const normalisedSelectAllOption =
        typeof clickedValue === 'string' && normalisedOptions.selectAllOptions.get(clickedValue);

    normalisedValue.options.forEach((normalisedOption) => {
        if (normalisedSelectAllOption) {
            const isOptionFromClickedGroup = normalisedOption.groupLabel === normalisedSelectAllOption.groupLabel;
            if (isOptionFromClickedGroup) return;
        }

        const isOptionClicked = normalisedOption.option.value === clickedValue;
        if (isOptionClicked) return;

        notClickedOptions.push(normalisedOption.option);
    });

    return notClickedOptions;
};

const computeNewValueWhenOptionFromGroupWithSelectAllIsClicked = <Option extends DefaultOption>(
    clickedOption: Option,
    optionsInField: Option[],
    clickedOptionNormalisedGroup: NormalisedGroup<GroupBase<Option>>,
    normalisedValue: NormalisedValue<Option, GroupBase<Option>>,
    {action}: ActionMeta<Option>
): PropsValue<Option> => {
    const availableOptionsInGroup = clickedOptionNormalisedGroup.group.options;

    const normalisedValueGroup = normalisedValue.groups.get(clickedOptionNormalisedGroup.group.label);
    const selectedOptionsInGroup = normalisedValueGroup?.group.options;

    const areAllInGroupSelected = availableOptionsInGroup.length === selectedOptionsInGroup?.length;

    const isOptionDeselectedWhenGroupAllOptionsSelected = action === 'deselect-option' && areAllInGroupSelected;
    if (!isOptionDeselectedWhenGroupAllOptionsSelected) return optionsInField;

    const remainingGroupOptions = availableOptionsInGroup.filter(
        (possibleValue) => possibleValue.value !== clickedOption.value
    );

    const groupSelectAllOptionIndex = optionsInField.findIndex(
        (v) => v.value === clickedOptionNormalisedGroup.groupSelectAllOptionValue
    );

    const modifiedNewValue = [...optionsInField];

    modifiedNewValue.splice(groupSelectAllOptionIndex, 1, ...remainingGroupOptions);

    return modifiedNewValue;
};

const checkIfOptionIsSelected = <Option extends DefaultOption | AllOption, Group extends GroupBase<Option>>(
    option: Option,
    selectAllOption: AllOption,
    isSelectAllOptionShown: boolean,
    normalisedOptions: NormalisedOptions<Option, Group>,
    normalisedValue: NormalisedValue<Option, Group>
) => {
    const areAllOptionsSelected = normalisedValue.options.size === normalisedOptions.options.size;
    if (areAllOptionsSelected) return true;

    const isSelectAllOption = isSelectAllOptionShown && option.value === selectAllOption.value;
    if (isSelectAllOption) {
        return areAllOptionsSelected;
    }

    const optionValue = option.value;
    const normalisedSelectAllOption =
        typeof optionValue === 'string' && normalisedOptions.selectAllOptions.get(optionValue);

    if (!normalisedSelectAllOption) return normalisedValue.options.has(option.value);

    const {groupLabel} = normalisedSelectAllOption;
    const normalisedGroup = normalisedOptions.groups.get(groupLabel);
    const availableOptionsInGroup = normalisedGroup.group.options;
    const selectedOptionsInGroup = normalisedValue.groups.get(groupLabel)?.group.options;

    const areAllInGroupSelected = availableOptionsInGroup.length === selectedOptionsInGroup?.length;

    return areAllInGroupSelected;
};

const computeInnerValue = <Option extends DefaultOption, Group extends GroupBase<Option>>(
    selectedOptions: Option[],
    normalisedOptions: NormalisedOptions<Option, Group>,
    normalisedValue: NormalisedValue<Option, Group>,
    selectAllOption: AllOption,
    isSelectAllOptionShown: boolean
) => {
    const areAllPossibleValuesSelected = normalisedOptions.options.size === normalisedValue.options.size;
    if (areAllPossibleValuesSelected && isSelectAllOptionShown) return [selectAllOption];

    // prevent redundant further calcualtion
    if (!normalisedOptions.selectAllOptions.size) return selectedOptions;

    const insertedGroupSelectAllOptions = new Set<string>();

    return selectedOptions.reduce((newValue, selectedOption) => {
        const {groupLabel} = normalisedOptions.options.get(selectedOption.value);
        if (!groupLabel) {
            return [...newValue, selectedOption];
        }

        const normalisedGroup = normalisedOptions.groups.get(groupLabel);
        const {groupSelectAllOptionValue} = normalisedGroup;

        if (!groupSelectAllOptionValue) {
            return [...newValue, selectedOption];
        }

        if (insertedGroupSelectAllOptions.has(groupLabel)) {
            return [...newValue];
        }

        const availableOptionsInGroup = normalisedGroup.group.options;
        const selectedOptionsInGroup = normalisedValue.groups.get(groupLabel).group.options;

        const areAllOptionsInGroupSelected = availableOptionsInGroup.length === selectedOptionsInGroup.length;
        if (!areAllOptionsInGroupSelected) return [...newValue, selectedOption];

        insertedGroupSelectAllOptions.add(groupLabel);

        const normalisedSelectAllOption = normalisedOptions.selectAllOptions.get(groupSelectAllOptionValue).option;

        return [...newValue, normalisedSelectAllOption];
    }, [] as (DefaultOption | AllOption)[]);
};

const computeNewValue = <Option extends DefaultOption, Group extends GroupBase<Option>>(
    optionsInField: (Option | AllOption)[],
    selectedOptions: Option[],
    normalisedOptions: NormalisedOptions<Option, Group>,
    normalisedValue: NormalisedValue<Option, Group>,
    actionMeta: ActionMeta<Option>,
    selectAllOption: AllOption,
    isSelectAllOptionShown: boolean
): MultiValue<Option> => {
    const {action, option, removedValue} = actionMeta;

    const isOptionAllClicked = isSelectAllOptionShown && (option || removedValue)?.value === selectAllOption.value;
    if (isOptionAllClicked)
        return computeNewValueWhenSelectAllIsClicked(normalisedOptions, actionMeta) as MultiValue<Option>;

    const areAllPossibleValuesSelected = normalisedOptions.options.size === normalisedValue.options.size;
    if (areAllPossibleValuesSelected && action === 'deselect-option') {
        return computeNewValueWhenEverythingIsSelectedAndOptionIsClicked(
            option,
            normalisedOptions,
            normalisedValue
        ) as MultiValue<Option>;
    }

    if (!normalisedOptions.selectAllOptions.size) return optionsInField as Option[];

    const optionValue = (removedValue || option).value;
    const normalisedSelectAllOption =
        typeof optionValue === 'string' && normalisedOptions.selectAllOptions.get(optionValue);
    if (normalisedSelectAllOption) {
        return computeNewValueWhenSelectAllInGroupIsClicked(
            normalisedSelectAllOption.groupLabel,
            selectedOptions,
            normalisedOptions,
            normalisedValue,
            actionMeta as ActionMeta<AllOption>
        ) as MultiValue<Option>;
    }

    const normalisedOption = normalisedOptions.options.get((option || removedValue).value);
    const normalisedGroup = normalisedOption.groupLabel && normalisedOptions.groups.get(normalisedOption.groupLabel);

    if (normalisedGroup?.groupSelectAllOptionValue) {
        return computeNewValueWhenOptionFromGroupWithSelectAllIsClicked(
            option,
            optionsInField,
            normalisedGroup,
            normalisedValue,
            actionMeta
        ) as MultiValue<Option>;
    }

    const optionsWithConvertedSelectAllOptions = optionsInField.reduce((options, optionInField) => {
        const {groups, selectAllOptions} = normalisedOptions;

        const optionValue = optionInField.value;
        const normalisedSelectAll = typeof optionValue === 'string' && selectAllOptions.get(optionValue);
        if (!normalisedSelectAll) return [...options, optionInField as Option];

        const normalisedGroup = groups.get(normalisedSelectAll.groupLabel);
        return [...options, ...normalisedGroup.group.options];
    }, [] as Option[]);

    return optionsWithConvertedSelectAllOptions;
};

export {
    checkIfOptionIsSelected,
    computeInnerValue,
    computeNewValue,
    computeNewValueWhenEverythingIsSelectedAndOptionIsClicked,
    computeNewValueWhenOptionFromGroupWithSelectAllIsClicked,
    computeNewValueWhenSelectAllInGroupIsClicked,
    computeNewValueWhenSelectAllIsClicked,
    customStylesFn,
    normaliseGroup,
    normaliseOption,
    normaliseValue,
};
