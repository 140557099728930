import {forwardRef} from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {InputBase, InputLabel} from '@mui/material';
import classnames from 'classnames';

import {MHLoader, MHToolTip} from '@/components/base';
import {anyDecimalNumbersRegExp} from '@/constants/RegExp';
import {getDecimalNumbersRegExp} from '@/utils/general';

import {MHTextInputProps} from './types';

import styles from './mhTextInput.module.scss';

export const MHTextInput = forwardRef(
    (
        {
            value,
            required = false,
            type = 'text',
            alignInputText = 'left',
            loading,
            placeholder = 'Enter text',
            disabled,
            name,
            label,
            labelPosition = 'top-left',
            decimalPlaces,
            onChange,
            onEnterKeyPressed,
            errorMessage,
            error,
            testId,
            infoIcon = false,
            info,
            tooltip,
            margin = '0 0 28px 0',
            ...other
        }: MHTextInputProps,
        textFieldRef,
    ) => {
        const infoContent = tooltip ? (
            <MHToolTip light placement="top-end" tooltipContent={tooltip}>
                {info ? <span className={styles.infoText}>{info}</span> : <InfoOutlinedIcon />}
            </MHToolTip>
        ) : (
            <>{info ? <span className={styles.infoText}>{info}</span> : <InfoOutlinedIcon />}</>
        );

        const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (type === 'number') {
                const value = e.target.value;
                const numbersRegExp =
                    decimalPlaces >= 0 ? getDecimalNumbersRegExp(decimalPlaces) : anyDecimalNumbersRegExp;

                if (!value || numbersRegExp.test(value)) {
                    onChange(e);
                }
            } else {
                onChange(e);
            }
        };

        const hasError = !!errorMessage || error;
        return (
            <div
                style={{
                    margin,
                }}
                className={classnames(styles.mhTextField, {[styles.mhTextFieldLeftLabel]: labelPosition === 'left'})}
                data-testid={testId}
            >
                {(label || infoIcon || info) && (
                    <div className={styles.labelBlock}>
                        {(label || ((infoIcon || info) && labelPosition === 'top-left')) && (
                            <InputLabel
                                classes={{
                                    root: classnames(styles.label, {
                                        [styles.labelError]: hasError,
                                        [styles.labelDisabled]: disabled,
                                        [styles.labelLeft]: labelPosition === 'left',
                                        [styles.noLabelMargin]: !info && !infoIcon,
                                    }),
                                }}
                                htmlFor={name}
                            >
                                {label} {required && '*'}
                            </InputLabel>
                        )}
                        {(infoIcon || info) && labelPosition === 'top-left' && (
                            <div className={styles.infoContent}>{infoContent}</div>
                        )}
                    </div>
                )}

                <div className={styles.inputBlock}>
                    <InputBase
                        classes={{
                            root: classnames(styles.inputRoot, {
                                [styles.inputRootError]: hasError,
                                [styles.inputRootDisabled]: disabled || loading,
                            }),
                            focused: styles.inputFocused,
                            input: classnames(styles.input, {
                                [styles.inputDisabled]: disabled || loading,
                                [styles.inputAlignRight]: alignInputText === 'right',
                            }),
                            adornedStart: styles.inputRootAdorned,
                            adornedEnd: styles.inputRootAdorned,
                        }}
                        type={type === 'number' ? 'text' : type}
                        inputRef={textFieldRef}
                        value={value}
                        placeholder={placeholder}
                        disabled={disabled || loading}
                        name={name}
                        onChange={onInputChange}
                        error={hasError}
                        id={name}
                        onKeyDown={(e) => {
                            if (onEnterKeyPressed && e.key === 'Enter') onEnterKeyPressed(e);
                        }}
                        {...other}
                    />

                    {loading && (
                        <div className={styles.loading}>
                            <MHLoader size="16px" />
                        </div>
                    )}

                    {errorMessage && (
                        <div className={styles.errorMessage}>
                            <span>{errorMessage}</span>
                        </div>
                    )}
                </div>

                {(infoIcon || info) && labelPosition === 'left' && (
                    <div className={classnames(styles.infoContent, styles.infoContentRight)}>{infoContent}</div>
                )}
            </div>
        );
    },
);
