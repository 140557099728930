import {
    ComponentType,
    FocusEventHandler,
    forwardRef,
    KeyboardEventHandler,
    ReactNode,
    Ref,
    useEffect,
    useRef,
} from 'react';
import Select, {
    GroupBase,
    MultiValueProps,
    OptionProps,
    OptionsOrGroups,
    SelectComponentsConfig,
    StylesConfig,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import {FilterOptionOption} from 'react-select/dist/declarations/src/filters';
import {WindowedMenuList} from 'react-windowed-select';
import InputLabel from '@mui/material/InputLabel';
import classnames from 'classnames';

import {
    ClearIndicator,
    DropdownIndicator,
    LoadingIndicator,
    MultiValue,
    MultiValueRemove,
    Option as MHOption,
} from './componentOverrides';
import {TypedOption, UseMHSelectOptionsProps} from './types';
import {useMHSelectOptions} from './useMHSelectOptions';
import {customStylesFn} from './utils';

import selectStyles from './styles/styles.module.scss';

type MHSelectPropsType<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
> = UseMHSelectOptionsProps<Option, IsMulti, Group> & {
    showRequiredAsterisk?: boolean;
    labelOnLeft?: boolean;
    largeDataSet?: boolean;
    customAutoFocus?: boolean;
    loading?: boolean;
    placeholder?: string;
    isSearchable?: boolean;
    name?: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onKeyDown?: KeyboardEventHandler;
    maxMenuHeight?: number;
    menuPlacement?: 'auto' | 'bottom' | 'top';
    hideSelectedOptions?: boolean;
    closeMenuOnSelect?: boolean;
    preventDisableWhileLoading?: boolean;
    label?: string;
    rootClassName?: string;
    externalClass?: string;
    externalSelectClass?: string;
    externalErrorClassName?: string;
    errorMessage?: string;
    openMenuOnFocus?: boolean;
    isClearable?: boolean;
    windowThreshold?: number;
    isDisabled?: boolean;
    noOptionsMessage?: (obj: {inputValue: string}) => ReactNode;
    filterOption?: ((option: FilterOptionOption<unknown>, inputValue: string) => boolean) | null;
    formattedOption?: ComponentType<OptionProps<unknown, boolean, GroupBase<unknown>>>;
    extendedCustomStyles?: StylesConfig<unknown, boolean, GroupBase<unknown>>;
    loadOptions?: (
        inputValue: string,
        callback: (options: OptionsOrGroups<Option, Group>) => void
    ) => Promise<OptionsOrGroups<Option, Group>> | void;
    overrideComponents?: (components: object) => object;
    closeMenuOnScroll?: boolean | ((event: Event) => boolean);
    menuPortalTarget?: HTMLElement;
    inputId?: string;
    testId?: string;
    externalLabelClass?: string;
    multiValueMaxVisible?: number;
    defaultOptions?: OptionsOrGroups<Option, Group>;
};

const DEFAULT_MAX_MENU_HEIGHT = 128;
const EXTENDED_MAX_MENU_HEIGHT = 175;

const MHSelectInner = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    {
        isSearchable = true,
        showRequiredAsterisk,
        labelOnLeft,
        isMulti,
        isDisabled,
        largeDataSet,
        showSelectAllOption,
        areSelectAllInGroupOptionsShown,
        optionGroupsWithSelectAll,
        selectAllOptionsInGroupsLabeling,
        customAutoFocus,
        loading,
        preventDisableWhileLoading,
        label,
        errorMessage,
        placeholder = 'Select...',
        maxMenuHeight = DEFAULT_MAX_MENU_HEIGHT,
        windowThreshold,
        overrideComponents,
        formattedOption,
        loadOptions,
        options = [],
        onChangeHandler,
        value,
        extendedCustomStyles,
        rootClassName,
        externalSelectClass,
        externalClass,
        closeMenuOnScroll,
        selectAllOptionLabel,
        inputId,
        testId,
        externalErrorClassName,
        externalLabelClass,
        multiValueMaxVisible,
        ...other
    }: MHSelectPropsType<Option, IsMulti, Group>,
    ref: Ref<any>
) => {
    const focusRef = useRef(null);

    const mhSelectOptions = useMHSelectOptions({
        onChangeHandler,
        value,
        isMulti,
        largeDataSet,
        options,
        selectAllOptionLabel,
        selectAllOptionsInGroupsLabeling,
        showSelectAllOption,
        optionGroupsWithSelectAll,
        areSelectAllInGroupOptionsShown,
    });
    const SelectRenderer = loadOptions ? AsyncSelect : Select;
    const placeholderText = placeholder || (isSearchable && 'Type to search');

    const isMaxMenuHeightDefault = maxMenuHeight === DEFAULT_MAX_MENU_HEIGHT && options.length > 3;

    useEffect(() => {
        if (customAutoFocus && focusRef?.current?.focus) focusRef.current.focus();
    }, [customAutoFocus, focusRef]);

    const defaultComponents: {[key: string]: unknown} = {
        ClearIndicator,
        MultiValueRemove,
        DropdownIndicator: loading || isDisabled ? null : DropdownIndicator,
        LoadingIndicator,
        MultiValue: (props: MultiValueProps<TypedOption<string, string>>) => (
            <MultiValue {...props} multiValueMaxVisible={multiValueMaxVisible} />
        ),
    };
    if (isMulti) defaultComponents.Option = formattedOption || MHOption;
    if (largeDataSet) defaultComponents.MenuList = WindowedMenuList;
    const components = overrideComponents ? overrideComponents(defaultComponents) : defaultComponents;

    const labelContainerStyles = classnames(selectStyles.labelContainer, {
        [selectStyles.labelContainerLeft]: labelOnLeft,
    });
    const labelStyles = classnames(selectStyles.label, {
        [selectStyles.labelDisabled]: isDisabled,
        [selectStyles.labelError]: errorMessage,
        [externalLabelClass]: externalLabelClass,
    });

    // Need this handler because default behaviour closeMenuOnScroll={true} not working
    const handleCloseMenuOnScroll = (e: Event) => {
        if (!closeMenuOnScroll) {
            return false;
        }
        if (typeof closeMenuOnScroll === 'function') {
            return closeMenuOnScroll(e);
        }
        return e.target === document;
    };

    return (
        <div
            className={classnames(selectStyles.mhSelectRoot, externalClass, rootClassName, {
                [selectStyles.mhSelectRootRowView]: labelOnLeft,
            })}
        >
            {label && (
                <div className={labelContainerStyles}>
                    <InputLabel className={labelStyles} htmlFor={inputId}>
                        {label} {showRequiredAsterisk && '*'}
                    </InputLabel>
                </div>
            )}

            <div
                data-testid={testId}
                className={classnames(selectStyles.mhSelectWrapper, {
                    [selectStyles.mhSelectWrapperSearchable]: isSearchable,
                    [externalSelectClass]: externalSelectClass,
                })}
            >
                <SelectRenderer
                    inputId={inputId}
                    windowThreshold={windowThreshold}
                    ref={ref || focusRef}
                    // @ts-ignore WindowedSelect variant messes with type inference here
                    isOptionSelected={mhSelectOptions.isOptionSelected}
                    maxMenuHeight={isMaxMenuHeightDefault ? maxMenuHeight : EXTENDED_MAX_MENU_HEIGHT}
                    styles={{...customStylesFn(extendedCustomStyles)}}
                    options={mhSelectOptions.options}
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    isLoading={loading}
                    isDisabled={preventDisableWhileLoading && !isDisabled ? false : isDisabled || loading}
                    placeholder={placeholderText}
                    value={mhSelectOptions.value}
                    // @ts-ignore WindowedSelect variant messes with type inference here
                    onChange={mhSelectOptions.onChange}
                    className={classnames(selectStyles.mhSelectContainer, {
                        [selectStyles.mhSelectContainerLoading]: loading,
                        [selectStyles.mhSelectContainerError]: errorMessage,
                    })}
                    classNamePrefix="mhSelect"
                    loadOptions={loadOptions}
                    closeMenuOnScroll={handleCloseMenuOnScroll}
                    menuShouldScrollIntoView={false}
                    components={components as SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>}
                    {...other}
                />
                {errorMessage && (
                    <div
                        className={classnames(selectStyles.errorMessage, {
                            [externalErrorClassName]: externalErrorClassName,
                        })}
                    >
                        {errorMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

const MHSelect = forwardRef(MHSelectInner);
(MHSelect as React.NamedExoticComponent).displayName = 'MHSelect';

export default MHSelect;
