import {useEffect} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {LoggingActions} from '@/constants/loggingActions';

import {logError} from './redux/slice';

// @ts-ignore
import styles from './styles.module.scss';

const ErrorPage = ({errorData, error}: any) => {
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        const data = {url: location.pathname, actionName: LoggingActions.pageCrashError, message: error, ...errorData};
        dispatch(logError(data));
    }, [dispatch, error, errorData, location.pathname]);

    return (
        <div className={styles.error}>
            <ErrorOutlineIcon className={styles.errorIcon} />
            <div className={styles.errorText}>Something went wrong</div>
        </div>
    );
};

const ErrorBoundaryComponent = ({children, errorData}: any) => (
    <ErrorBoundary FallbackComponent={(props) => <ErrorPage errorData={errorData} {...props} />}>
        {children}
    </ErrorBoundary>
);

export default ErrorBoundaryComponent;
