import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';

import styles from './styles.module.scss';

type MHLoaderProps = {
    className?: string;
    rootClassName?: string;
    color?: 'primary' | 'secondary' | 'inherit';
    size?: number | string;
};
// TODO set colors striaght in the component.
// Currently it is specified in the theme
const MHLoader = ({className, rootClassName, ...other}: MHLoaderProps) => (
    <div className={classnames(styles.wrapper, rootClassName)}>
        <CircularProgress classes={{root: className}} {...other} />
    </div>
);

export default MHLoader;
