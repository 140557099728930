const zipCodeExp = /^[0-9]{5}(?:-[0-9]{4})?$/;
const zipExpForServiceArea = /[^0-9 ,]/;

const dateFormatRegExp = /(19|20)([0-9]{2})[- /.](0[1-9]|[12][0-9]|3[01]|[1-9])[- /.](0[1-9]|1[012]|[1-9])/;
const extendedDateFormatRegExp = /^(?:(0[1-9]|1[012]|[1-9])[- /.](0[1-9]|[12][0-9]|3[01]|[1-9])[- /.](19|20)[0-9]{2})$/;
const onlyNumbersRegExp = /[^0-9]/g;

// 9 to 14 digit alphanumeric string
const alphanumeric = /^[A-Za-z0-9]{9,14}$/;

const integerNumbersRegExp = /^\d+$/;

const integersRegExp = /^0$|^[1-9]\d{0,9}$/;
const decimalsRegExp = /^0$|^0?[,.]\d{0,10}$|^[1-9]\d{0,9}[,.]\d{0,10}$|^[1-9]\d{0,9}$/;

// eslint-disable-next-line
const anyDecimalNumbersRegExp = /^[-+]?\d*[\.|\,]?\d*$/;

export {
    alphanumeric,
    anyDecimalNumbersRegExp,
    dateFormatRegExp,
    decimalsRegExp,
    extendedDateFormatRegExp,
    integerNumbersRegExp,
    integersRegExp,
    onlyNumbersRegExp,
    zipCodeExp,
    zipExpForServiceArea,
};
