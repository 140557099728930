import {MouseEvent, ReactNode} from 'react';
import MUITableCell from '@mui/material/TableCell';
import classnames from 'classnames';

import styles from './mhTableCellElement.module.scss';

type MHTableCellElementProp = {
    children?: ReactNode;
    onClick?: (event: MouseEvent<unknown>) => void;
    externalStyles?: string;
    colSpan?: number;
    rowSpan?: number;
};
export const MHTableCellElement = ({
    children,
    onClick,
    externalStyles = '',
    colSpan,
    rowSpan,
}: MHTableCellElementProp) => {
    return (
        <MUITableCell
            rowSpan={rowSpan}
            colSpan={colSpan}
            className={classnames(styles.tableCell, {[externalStyles]: !!externalStyles})}
            onClick={onClick}
        >
            {children}
        </MUITableCell>
    );
};
