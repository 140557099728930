import {useCallback, useEffect, useRef, useState} from 'react';

export const useExpandHook = <T>(isSingleExpand = false, isRowExpandable: T) => {
    const [expandedRowsInternal, setExpandedRowsInternal] = useState<Record<string, boolean>>({});
    const proxy = useRef(expandedRowsInternal);
    proxy.current = expandedRowsInternal;

    const toggleExpand = useCallback(
        (rowId: string | number) => {
            let expandedRowsCopy = {...proxy.current};
            const rowAlreadySelected = !!expandedRowsCopy[rowId];

            if (rowAlreadySelected) delete expandedRowsCopy[rowId];
            else if (isSingleExpand) expandedRowsCopy = {[rowId]: true};
            else expandedRowsCopy[rowId] = true;

            setExpandedRowsInternal(expandedRowsCopy);
        },
        [isSingleExpand]
    );

    useEffect(() => {
        setExpandedRowsInternal({});
    }, [isSingleExpand, isRowExpandable]);

    return {expandedRowsInternal, toggleExpand};
};
