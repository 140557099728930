import {memo, MouseEvent, ReactNode} from 'react';

import {MHTableCellElement} from '@/components/base';

import styles from './mhTableCell.module.scss';

type MHTableCellProps = {
    onClick?: (event: MouseEvent<unknown>) => void;
    disableRowAction?: boolean;
    cellStyle?: string;
    noData?: ReactNode;
    children?: ReactNode;
};

const MHTableCellNotMemoized = ({
    onClick,
    disableRowAction = false,
    noData = '-',
    children,
    cellStyle,
}: MHTableCellProps) => {
    const hasContent = Boolean(children) || children === 0;

    const cursorStyles = disableRowAction ? styles.cursorInitial : '';

    return (
        <MHTableCellElement
            externalStyles={`${cursorStyles} ${styles.tableCellFirstLast} ${cellStyle}`}
            onClick={disableRowAction ? (e) => e.stopPropagation() : onClick}
        >
            {hasContent ? children : noData}
        </MHTableCellElement>
    );
};

export const MHTableCell = memo(MHTableCellNotMemoized) as typeof MHTableCellNotMemoized;
