import {MouseEventHandler, ReactNode} from 'react';
import {IconButton} from '@mui/material';
import classnames from 'classnames';

import {MHLoader} from '@/components/base';

import styles from './mhIconButton.module.scss';

enum BtnType {
    PrimaryPositive = 'primary-positive',
    SecondaryPositive = 'secondary-positive',
    PrimaryDanger = 'primary-danger',
}

enum BtnSize {
    Medium = 'medium',
    Small = 'small',
}

type ButtonType = `${BtnType}`;
type ButtonSize = `${BtnSize}`;

const classBtnTypeMapper: Record<BtnType, string> = {
    [BtnType.PrimaryPositive]: styles.primaryPositiveIconBtn,
    [BtnType.SecondaryPositive]: styles.secondaryPositiveIconBtn,
    [BtnType.PrimaryDanger]: styles.primaryDangerIconBtn,
};
const classBtnSizeMapper: Record<BtnSize, string> = {
    [BtnSize.Medium]: styles.mediumBtn,
    [BtnSize.Small]: styles.smallBtn,
};

type MHIconButtonProps = {
    btnType: ButtonType;
    children: ReactNode;
    btnSize?: ButtonSize;
    margin?: string;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    loading?: boolean;
    disabled?: boolean;
    name?: string;
    type?: 'submit' | 'button';
};

export const MHIconButton = ({
    btnType,
    children,
    btnSize = 'medium',
    margin = '0',
    onClick,
    loading,
    disabled,
    name,
    type,
}: MHIconButtonProps) => {
    const classNameBtnType = classBtnTypeMapper[btnType];
    const classNameBtnSize = classBtnSizeMapper[btnSize];

    return (
        <IconButton
            style={{
                margin,
            }}
            name={name}
            type={type}
            size={btnSize}
            onClick={onClick}
            disabled={loading || disabled}
            className={classnames(classNameBtnType, classNameBtnSize)}
        >
            {children}
            {loading && (
                <MHLoader color="primary" size={'20px'} data-testid="loader" rootClassName={styles.loaderClass} />
            )}
        </IconButton>
    );
};
