import {ElementType} from 'react';
import {Collapse, TableRow} from '@mui/material';

import {MHTableCellElement} from '@/components/base';

import styles from './expandedRow.module.scss';

type ExpandedRowContentProps<T> = {
    colsLength: number;
    row: T;
    expandableContent: ElementType<{row: T}>;
    syncExpandable?: boolean;
    isExpanded: boolean;
};
export const ExpandedRow = <T,>({
    colsLength,
    expandableContent: Content,
    syncExpandable = false,
    row,
    isExpanded,
}: ExpandedRowContentProps<T>) => {
    if (syncExpandable && isExpanded) return <Content row={row} />;
    return (
        <TableRow>
            <MHTableCellElement colSpan={colsLength} externalStyles={styles.expandedRowColumn}>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <div className={styles.expandedRowColumnContentCollapse}>
                        <Content row={row} />
                    </div>
                </Collapse>
            </MHTableCellElement>
        </TableRow>
    );
};
